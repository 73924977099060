
import { Component } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import NotAllowedComponent from '@/components/views/settings/NotAllowedComponent.vue';
import { NotificationTriggers } from '@/enums/components/CmsIndex/NotificationTriggers';
import NotificationSettings from '@/models/NotificationSettings';
import NotificationSettingsRepository from '@/repositories/NotificationSettingsRepository';
import { NotificationSettingsTypes } from '@/interfaces/components/cms/NotificationSettingsTypes';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
@Component({
    name: 'Notifications',
    components: {
        SettingsTopBar,
        NotAllowedComponent,
    },
})
export default class Notifications extends mixins<UserRightsMixin>(UserRightsMixin) {
    private backRoute: RouteNames = RouteNames.settings;
    private isLoading: boolean = false;
    private NotificationTriggers = NotificationTriggers;
    private booleanFields: NotificationSettingsTypes = {
        NEW_ATTACHMENT: false,
        BIRTHDAY: false,
        CLIENT_COMMERCIALIST: false,
        ORDER_FINISHED: false,
        PAYMENT: false,
        PROJECT_COMMENT: false,
        PROJECT_COMMERCIALIST: false,
        PROJECT_ORDERED_COMMERCIALIST: false,
        PROJECT_ORDERED: false,
        PROJECT_ORDERED_SALES: false,
    };

    private get userHasRights() {
        return this.isSuperAdmin;
    }

    private get notificationSettings(): NotificationSettingsTypes {
        return NotificationSettingsRepository.getAll()!.settings;
    }

    private setBooleanFields() {
        if (!this.notificationSettings) {
            return;
        }

        for (const key of Object.keys(this.notificationSettings) as Array<keyof NotificationSettingsTypes>) {
            if (Object.prototype.hasOwnProperty.call(this.notificationSettings, key)) {
                this.booleanFields[key] = this.notificationSettings[key];
            }
        }
    }

    private async setSettings() {
        this.isLoading = true;

        await this.fetchSettings();
        this.setBooleanFields();
        await this.$nextTick();

        this.isLoading = false;
    }

    private async fetchSettings() {
        try {
            await NotificationSettings.getAll();
        } catch (error) {
            Promise.reject(error);

            this.$notification.error({
                message: this.$t('Error fetching settings') as string,
                description: '',
            });
        }
    }

    private async saveSettings() {
        try {
            await NotificationSettings.updateSettings(this.booleanFields);
        } catch (error) {
            Promise.reject(error);

            this.$notification.error({
                message: this.$t('Error savings settings') as string,
                description: '',
            });
        }
    }

    private async saveAndFetchSettings() {
        this.isLoading = true;

        try {
            await this.saveSettings();
        } catch (error) {
            this.isLoading = false;
            Promise.reject(error);
        }

        this.setBooleanFields();
        await this.$nextTick();
        this.isLoading = false;

        this.$notification.success({
            message: this.$t('Settings saved successfully') as string,
            description: '',
        });
    }

    private async mounted() {
        this.setSettings();
        EventBus.$on(EventBusEvents.saveNotificationsSettings, this.saveAndFetchSettings);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.saveNotificationsSettings, this.saveAndFetchSettings);
    }
}
