export enum NotificationTriggers {
    NEW_ATTACHMENT = 'New attachment',
    BIRTHDAY = 'Birthday',
    CLIENT_COMMERCIALIST = 'Client commercialist',
    ORDER_FINISHED = 'Order finished',
    PAYMENT = 'Payment',
    PROJECT_COMMENT = 'Project comment',
    PROJECT_COMMERCIALIST = 'Project commercialist',
    PROJECT_ORDERED_COMMERCIALIST = 'Project ordered commercialist',
    PROJECT_ORDERED = 'Project ordered',
    PROJECT_ORDERED_SALES = 'Project ordered sales',
}
